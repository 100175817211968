<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池编号" name="batteryCode">
              <a-input
                placeholder="请输入电池编号"
                v-model:value="form.batteryCode"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operator">
              <a-select
                v-model:value="form.operator"
                placeholder="请选择所属运营商"
              >
                <a-select-option value="0"
                  >厦门远丞科技有限公司</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="point">
              <a-select
                v-model:value="form.point"
                placeholder="请选择所属租赁点"
              >
                <a-select-option value="0">福建厦门三行网点</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="品牌" name="brand">
              <a-select v-model:value="form.brand" placeholder="请选择所属品牌">
                <a-select-option value="0">顶宇锂电</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="型号" name="model">
              <a-input
                placeholder="请输入型号"
                v-model:value="form.model"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="告警名称" name="alertName">
              <a-select
                v-model:value="form.alertName"
                placeholder="请选择告警名称"
              >
                <a-select-option value="0">状态异常保护</a-select-option>
                <a-select-option value="0">环境低温告警</a-select-option>
                <a-select-option value="0">充电过流保护</a-select-option>
                <a-select-option value="0">总电压低告警</a-select-option>
                <a-select-option value="0">单芯欠压保护</a-select-option>
                <a-select-option value="0">电芯低温告警</a-select-option>
                <a-select-option value="0">单芯间压差过大</a-select-option>
                <a-select-option value="0">MOS异常</a-select-option>
                <a-select-option value="0">环境高温告警</a-select-option>
                <a-select-option value="0">低温保护</a-select-option>
                <a-select-option value="0">单芯压差过大告警</a-select-option>
                <a-select-option value="0">单芯过压保护</a-select-option>
                <a-select-option value="0">电芯高温告警</a-select-option>
                <a-select-option value="0">单芯电压低告警</a-select-option>
                <a-select-option value="0">总电压过压保护</a-select-option>
                <a-select-option value="0">过温保护</a-select-option>
                <a-select-option value="0">MOS高温告警</a-select-option>
                <a-select-option value="0">放电过流保护</a-select-option>
                <a-select-option value="0">总电压高告警</a-select-option>
                <a-select-option value="0">短路保护</a-select-option>
                <a-select-option value="0">单芯电压高告警</a-select-option>
                <a-select-option value="0">总电压欠压保护</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="告警开始时间" name="alertStartTime">
              <a-range-picker
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                :placeholder="['开始日期', '结束日期']"
                @change="onChange"
                style="width: 100%"
                v-model:value="form.alertStartTime"
                @ok="onOk"
              /> </a-form-item
          ></a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <ud-pro-table
        ref="table"
        :rowKey="(record) => record.batteryCode"
        :columns="columns"
        :datasource="data"
        @change="handleChange"
        :scroll="{ x: 'max-content' }"
      >
        <template #toolbar>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项目吗？">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table></a-card
    >

    <!-- <real-name-detail v-model:visible="showDetail" :data="current || {}" /> -->
    <!-- <operation-edit v-model:visible="showEdit" :data="current" @done="reload" /> -->
  </div>
</template>

<script>
// import realNameDetail from './realNameDetail.vue'
// import operationEdit from './operation-edit.vue'
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    // realNameDetail
    // DownOutlined,
    // ExclamationCircleOutlined
    // operationDetail,
    // operationEdit
  },
  data() {
    return {
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      actionForm: { endTime: '' },
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '电池编号',
          // width: 170,
          // align: 'center',
          dataIndex: 'batteryCode'
        },
        {
          title: '电池品牌',
          // width: 85,
          // align: 'center',
          dataIndex: 'brand'
        },
        {
          title: '电池型号',
          // width: 175,
          // align: 'center',
          dataIndex: 'model'
        },
        {
          title: '告警名称',
          // width: 175,
          // align: 'center',
          dataIndex: 'alertName'
        },
        {
          title: '告警类型',
          // width: 175,
          // align: 'center',
          dataIndex: 'alertType'
        },
        {
          title: '告警开始时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'alertStartTime'
        },
        {
          title: '告警结束时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'alertEndTime'
        },
        {
          title: '所属运营商',
          // width: 175,
          // align: 'center',
          dataIndex: 'operator'
        },
        {
          title: '所属租赁点',
          // width: 175,
          // align: 'center',
          dataIndex: 'point'
        }
        // {
        //   title: '操作',
        //   width: 200,
        //   dataIndex: 'action',
        //   align: 'center',
        //   slots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      data: [
        {
          batteryCode: 'BT97328572357273282289',
          brand: '三元锂电',
          model: '72v55Ah',
          alertName: '单芯电压低告警',
          alertType: '电池告警',
          alertStartTime: '2022-01-14 09:45:36',
          alertEndTime: '2022-01-15 09:45:36',
          operator: '厦门远丞科技有限公司',
          point: '明发2'
        },
        {
          batteryCode: 'BT97328572357273282290',
          brand: '顶宇锂电',
          model: '72v55Ah',
          alertName: '单芯电压低告警',
          alertType: '电池告警',
          alertStartTime: '2022-01-14 09:45:36',
          alertEndTime: '2022-01-15 09:45:36',
          operator: '厦门远丞科技有限公司',
          point: '明发2'
        }
      ]
    }
  },
  methods: {
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
